import {
  IonContent,
  IonPage,
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
  IonFab,
  IonButton,
  IonFabButton
} from "@ionic/react";
import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import moment from "moment";
import "moment/locale/es";

import "./Home.css";
import Player from "../components/player";

const Home: React.FC = (props: any) => {
  console.log(props.match.params.url);
  const misas: any = [
    {
      ciudad: "Cúcuta",
      camara: "",
      horario: ["10:30 am", "04:00 pm"]
    },
    {
      ciudad: "Bucaramanga",
      horario: ["05:00 am"]
    }
  ];

  // function proximaMisa(horario: Array<string>) {
  //   return horario
  //     .filter((hora: string) => {
  //       if (moment(hora, "hh:mm a").isAfter(new Date())) return hora;
  //     })
  //     .splice(0, 1);
  // }

  // console.log(proximaMisa(misas.bucaramanga.horario));

  return (
    <IonPage>
      <IonContent>
        <IonGrid style={{ zIndex: 1, position: "relative" }}>
          <IonRow>
            <IonCol
              className="ion-no-padding"
              size="12"
              offsetMd="2"
              sizeMd="8"
            >
              <Player url={decodeURIComponent(props.match.params.url)} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonText
              color="secondary"
              className="ion-text-center"
              style={{ width: "100%" }}
            >
              <h1 style={{ fontSize: "7vmin" }}>Santa Misa</h1>
            </IonText>
            <IonText
              color="primary"
              className="ion-text-center"
              style={{ width: "100%" }}
            >
              <h3 style={{ fontSize: "5vmin" }}>
                Transmisión en vivo <b>todos los domingos</b>
              </h3>
              <h3 style={{ fontSize: "5vmin" }}>
                Cúcuta <b>10:30 a.m.</b> - Bucaramanga <b>4:00 p.m.</b>
              </h3>
            </IonText>
          </IonRow>
        </IonGrid>
        <img
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "27vw",
            zIndex: 0,
            opacity: 0.05
          }}
          src="./assets/cruz.svg"
        />
      </IonContent>
    </IonPage>
  );
};

export default Home;
