import React, { useState, useEffect, useRef } from "react";
import {
  IonCard,
  IonFab,
  IonButton,
  IonFabButton,
  IonIcon
} from "@ionic/react";
import {
  albumsOutline,
  expandOutline,
  contractOutline,
  pause,
  playForward
} from "ionicons/icons";
import ReactPlayer from "react-player";

const Player: React.FC<{ url: string }> = ({ url }) => {
  const [isLive, setLive] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isPaused, setPaused] = useState(false);
  const [isShowingLiveToast, setIsShowingLiveToast] = useState(false);
  const [progress, setProgress] = useState(0);
  var timer: any = null;
  useEffect(() => {
    if (!isLive) {
      console.log("consultando video");
      let i = 0;
      timer = setInterval(async () => {
        i = i++;
        await fetch(`http://201.232.181.68:8200/live/misa/index.m3u8`).then(
          (res: any) => {
            if (res.ok) {
              clearInterval(timer);
              setIsShowingLiveToast(true);
              setLive(true);
            } else {
              setLive(false);
            }
          }
        );
      }, 1500);
    }
  }, [isLive]);

  const player: any = useRef();

  return (
    <IonCard
      className="ion-no-padding ion-no-margin"
      style={{ display: "flex", minHeight: "100%" }}
    >
      {/* <IonFab horizontal="start" vertical="top">
        <IonButton
          onClick={() => {
            if (player) player.current.seekTo(0.9);
          }}
          size="small"
          color={
            isPaused
              ? "medium"
              : isLive
              ? progress < 50
                ? "danger"
                : "medium"
              : "light"
          }
          style={{ textTransform: "unset", opacity: "0.8" }}
        >
          {isPaused ? (
            <IonIcon icon={pause} />
          ) : isLive ? (
            progress < 50 ? (
              "En vivo"
            ) : (
              "medium"
            )
          ) : (
            "Cargando..."
          )}
        </IonButton>
      </IonFab> */}
      <ReactPlayer
        ref={player}
        style={{ objectFit: "cover", display: "flex" }}
        width="100%"
        height="100%"
        controls={false}
        url={url}
        playing={true}
        progressInterval={3000}
        onPause={() => {
          setPaused(true);
        }}
        onPlay={() => {
          setPaused(false);
          setLive(true);
        }}
        onError={e => {
          console.log("error", e);
          setLive(false);
        }}
      />
      <img
        className="ion-padding"
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          opacity: 0.1,
          width: "30%",
          zIndex: 0,
          pointerEvents: "none"
        }}
        src="./assets/logo-tinta.svg"
      />

      {/* <IonFab vertical="bottom" horizontal="end">
        <IonFabButton
          translucent
          color="light"
          style={{ opacity: 0.4 }}
          onClick={() => player.current.getInternalPlayer().requestFullscreen()}
        >
          <IonIcon icon={isFullScreen ? contractOutline : expandOutline} />
        </IonFabButton>
      </IonFab> */}
    </IonCard>
  );
};

export default Player;
